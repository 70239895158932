import React from "react";
import {
  Accordion,
  Text,
  Table,
  Title,
  Group,
  Stack,
  Paper,
  Tooltip,
} from "@mantine/core";
import { SearchPreview } from "../../../api/generated/schemas";
import AddressCell from "./Address";
import { IconHelp } from "@tabler/icons-react";

interface PartnerCardProps {
  preview: SearchPreview;
}

const PartnerCard: React.FC<PartnerCardProps> = ({ preview }) => {
  const { partner, mappings } = preview;

  return (
    <Paper shadow="lg" radius="lg" withBorder p="xs">
      {!mappings ? (
        <Stack pb="xs">
          <Group pl="1rem" pt="1rem" pr="1rem" justify="space-between">
            <Title order={3}>{partner.name}</Title>
            <Tooltip label="Partner without existing system accounts.">
              <IconHelp color="grey" />
            </Tooltip>
          </Group>
          <Text pl="1rem" c="dimmed">
            AMS ID: {partner.id}
          </Text>
        </Stack>
      ) : (
        <Accordion>
          <Accordion.Item value={partner.name}>
            <Accordion.Control>
              <Title order={3}>{partner.name}</Title>
            </Accordion.Control>
            <Stack pb="xs">
              <Text pl="1rem" c="dimmed">
                AMS ID: {partner.id}
              </Text>
            </Stack>

            <Accordion.Panel>
              <Table.ScrollContainer type="native" minWidth="1500px">
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>System</Table.Th>
                      <Table.Th>System Account ID</Table.Th>
                      <Table.Th>Business Unit ID</Table.Th>
                      <Table.Th>Organisation</Table.Th>
                      <Table.Th>Organisation ID</Table.Th>
                      <Table.Th>Company Number</Table.Th>
                      <Table.Th w="100px">Address</Table.Th>
                      <Table.Th>Contact Email</Table.Th>
                      <Table.Th>Primary Contact</Table.Th>
                      <Table.Th>Phone Number</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {mappings.map((mapping) => {
                      const {
                        systemName,
                        systemPartnerId,
                        businessUnit,
                        companyRegistrationNumber,
                        address,
                        primaryContact,
                      } = mapping;
                      const {
                        id: businessUnitId,
                        name,
                        organisationId,
                      } = businessUnit || {};
                      const {
                        contactEmail,
                        firstName,
                        lastName,
                        contactNumber,
                      } = primaryContact || {};

                      return (
                        <Table.Tr key={mapping.id}>
                          <Table.Td>{systemName}</Table.Td>
                          <Table.Td>{systemPartnerId}</Table.Td>
                          <Table.Td>{businessUnitId}</Table.Td>
                          <Table.Td>{name}</Table.Td>
                          <Table.Td>{organisationId}</Table.Td>
                          <Table.Td>{companyRegistrationNumber}</Table.Td>
                          <Table.Td>
                            <AddressCell address={address} />
                          </Table.Td>
                          <Table.Td>{contactEmail}</Table.Td>
                          <Table.Td>
                            {firstName && `${firstName} ${lastName}`}
                          </Table.Td>
                          <Table.Td>{contactNumber}</Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </Table.Tbody>
                </Table>
              </Table.ScrollContainer>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      )}
    </Paper>
  );
};

export default PartnerCard;
