import { PublicClientApplication } from "@azure/msal-browser";
import { envConfig } from "../index";

export const acquireToken = async () => {
  try {
    const response = await msalInstance.loginRedirect(loginRequest);

    return response.accessToken;
  } catch (error) {
    console.log("Error acquiring token", error);
  }
};

export const loginRequest = {
  scopes: ["openid", "profile", "email", "offline_access"].concat(
    envConfig.scopes,
  ),
};

export const msalConfig = {
  auth: {
    clientId: envConfig.clientId,
    authority: envConfig.authority,
    redirectUri: envConfig.redirectUri,
    postLogoutRedirectUri: envConfig.postLogoutRedirectUri,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
