import React, { FC } from "react";
import { Alert, Center } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

const ErrorAlert: FC = () => {
  return (
    <Center h={100}>
      <Alert icon={<IconAlertCircle size={16} />} title="Error" color="red">
        There was a problem retrieving data from the server. Please try again
        later or contact the administrator.
      </Alert>
    </Center>
  );
};

export default ErrorAlert;
