import { useMsal } from "@azure/msal-react";
import React from "react";
import { ActionIcon, Tooltip } from "@mantine/core";
import { IconLogin2 } from "@tabler/icons-react";
import { loginRequest } from "../../config/auth/authConfig";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <Tooltip label="Sign in">
      <ActionIcon variant="subtle" onClick={handleLogin}>
        <IconLogin2 />
      </ActionIcon>
    </Tooltip>
  );
};
