import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";

import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import Navigation from "./layout/Navigation";
import SearchPage from "./features/Search/SearchPage";
import { QueryClientProvider } from "./providers/QueryClientProvider";
import LoadingAuthentication from "./components/login/LoadingAuthentication";
import {
  createTheme,
  MantineColorsTuple,
  MantineProvider,
} from "@mantine/core";

type AppProps = {
  pca: IPublicClientApplication;
};

const primaryColor: MantineColorsTuple = [
  "#f9edfc",
  "#eed6f5",
  "#ddaaee",
  "#cc7ae6",
  "#bd53df",
  "#b43cdc",
  "#af2fdb",
  "#9a24c2",
  "#891eae",
  "#771598",
];

const theme = createTheme({
  primaryColor: "dark-pink",
  colors: {
    "dark-pink": primaryColor,
  },
});

function App({ pca }: AppProps) {
  const navigate = useNavigate();

  useEffect(() => {
    pca.handleRedirectPromise().then((response) => {
      if (response?.account) {
        navigate("/search", { replace: true });
      }
    });
    const account = pca.getActiveAccount();
    if (account) {
      navigate("/search", { replace: true });
    } else {
      pca.initialize();
    }
  }, []);

  return (
    <MsalProvider instance={pca}>
      <MantineProvider theme={theme}>
        <QueryClientProvider>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            loadingComponent={LoadingAuthentication}
          >
            <Navigation>
              <SearchPage />
            </Navigation>
          </MsalAuthenticationTemplate>
        </QueryClientProvider>
      </MantineProvider>
    </MsalProvider>
  );
}

export default App;
