import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "@mantine/core/styles.css";
import { BrowserRouter } from "react-router-dom";
import {
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalInstance } from "./config/auth/authConfig";

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
  );

  root.render(
    <BrowserRouter>
      <React.StrictMode>
        <App pca={msalInstance} />
      </React.StrictMode>
    </BrowserRouter>,
  );
});
