import { env } from "../services/envService";
import { configDev } from "./config.dev";
import { configUat } from "./config.uat";
import { configLive } from "./config.live";
import { configLocal } from "./config.local";

interface EnvConfig {
  clientId: string;
  authority: string;
  postLogoutRedirectUri: string;
  redirectUri: string;
  scopes: string[];
  apiUrl: string;
}

function getConfig(): EnvConfig {
  let config: EnvConfig = configLocal;
  const stage = env.REACT_APP_STAGE;

  if (stage?.includes("uat")) {
    config = configUat;
  } else if (stage?.includes("live")) {
    config = configLive;
  } else if (stage?.includes("dev")) {
    config = configDev;
  }

  return config;
}

export const envConfig = getConfig();
