import React, {
  Dispatch,
  FC,
  SetStateAction,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  ActionIcon,
  CloseButton,
  Group,
  Stack,
  TextInput,
} from "@mantine/core";
import { IconFilterOff, IconSearch } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import TermBadge from "./TermBadge";
import { PaginationType } from "../hooks/usePagination";

export interface SearchFormProps {
  setTerms: Dispatch<SetStateAction<string[]>>;
  terms: string[];
  setPagination: Dispatch<SetStateAction<PaginationType>>;
}

const TRIMMED_WITHIN_SIZE_REGEX = /^\S(.{1,48})\S$/;

const SearchForm: FC<SearchFormProps> = ({
  setTerms,
  terms,
  setPagination,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const removeBadge = useCallback(
    (label: string) => {
      setPagination((prevPagination) => ({ ...prevPagination, page: 0 }));
      setTerms((terms) => terms.filter((badge) => badge !== label));
    },
    [setTerms, setPagination],
  );

  const addBadge = useCallback(
    (label: string) => {
      handleClear();

      if (!terms.includes(label)) {
        setTerms((prevTerms) => [...prevTerms, label]);
      }
    },
    [setTerms, terms],
  );

  const form = useForm({
    initialValues: {
      search: "",
    },
    validate: {
      search: (value) => {
        if (terms.includes(value)) {
          return "The search term you entered is already in use. Please choose a different term or modify your existing search.";
        } else if (!TRIMMED_WITHIN_SIZE_REGEX.test(value)) {
          return "Please ensure search term is between 3 and 50 characters long, starting and ending with non-whitespace characters.";
        }
        return null;
      },
    },
  });

  const handleClear = useCallback(() => {
    form.reset();
    setIsDirty(false);
    setPagination((prevPagination) => ({ ...prevPagination, page: 0 }));
    inputRef.current?.focus();
  }, [form, setPagination]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    form.setFieldValue("search", value);
    setIsDirty(value.length > 0);
  };

  const handleSubmit = (values: { search: string }) => {
    addBadge(values.search);
    inputRef.current?.focus();
  };

  return (
    <Stack w="800px">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          ref={inputRef}
          placeholder="Search"
          aria-label="Search input"
          rightSectionPointerEvents="all"
          radius="md"
          size="md"
          leftSection={<IconSearch color="purple" stroke={1.5} />}
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={handleClear}
              style={{ display: isDirty ? "block" : "none" }}
            />
          }
          {...form.getInputProps("search")}
          onChange={handleChange}
        />
      </form>
      <Group>
        {terms.length > 0 && (
          <ActionIcon
            variant="subtle"
            aria-label="Settings"
            onClick={() => setTerms([])}
          >
            <IconFilterOff />
          </ActionIcon>
        )}
        {terms.map((label) => (
          <TermBadge key={label} label={label} onRemove={removeBadge} />
        ))}
      </Group>
    </Stack>
  );
};

export default SearchForm;
