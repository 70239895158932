import React, { Dispatch, FC, SetStateAction } from "react";
import { Group, NativeSelect, Pagination, Text } from "@mantine/core";
import { PaginationType } from "../hooks/usePagination";
import { Page } from "../../../api/generated/schemas";

export interface PaginationProps {
  setPagination: Dispatch<SetStateAction<PaginationType>>;
  page: Page;
}

const PaginationWithSize: FC<PaginationProps> = ({ setPagination, page }) => {
  const { total, pageNumber, pageSize } = page;

  return (
    <Group>
      <Pagination
        value={pageNumber + 1}
        onChange={(page) => setPagination({ size: pageSize, page: page - 1 })}
        total={Math.ceil(total / pageSize)}
      />
      <Text size="sm">Page size:</Text>
      <NativeSelect
        data={["5", "10", "20", "50", "100"]}
        value={page.pageSize.toString()}
        onChange={(e) =>
          setPagination({ size: Number(e.currentTarget.value), page: 0 })
        }
      />
    </Group>
  );
};

export default PaginationWithSize;
