import React, { FC } from "react";
import { Badge, CloseButton, Group } from "@mantine/core";

export interface BadgeProps {
  label: string;
  onRemove: (label: string) => void;
}

const TermBadge: FC<BadgeProps> = ({ label, onRemove }) => {
  return (
    <Badge variant="filled" size={"lg"}>
      <Group gap={"xs"}>
        {label}
        <CloseButton
          size="sm"
          variant={"transparent"}
          onClick={() => onRemove(label)}
        />
      </Group>
    </Badge>
  );
};

export default TermBadge;
