import React from "react";
import { Loader, Container, Text, Stack } from "@mantine/core";

const LoadingAuthentication = () => {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Stack align={"center"}>
        <Loader size="md" style={{ marginRight: 10 }} />
        <Text>Please wait while we redirect you to the page...</Text>
      </Stack>
    </Container>
  );
};

export default LoadingAuthentication;
