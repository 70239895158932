import React, { FunctionComponent } from "react";
import { Group, Text, useMantineTheme } from "@mantine/core";
import { SignInOut } from "../components/login/SignInOut";

const Header: FunctionComponent = () => {
  const theme = useMantineTheme();

  return (
    <Group
      bg={theme.colors["dark-pink"][9]}
      justify="space-between"
      h="100%"
      px="md"
    >
      <Text fw={700} c={"white"}>
        AMS Partner Search Portal
      </Text>
      <SignInOut />
    </Group>
  );
};

export default Header;
