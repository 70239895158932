import React, { Dispatch, FC, SetStateAction } from "react";
import { Anchor, Card, Divider, Modal, Text } from "@mantine/core";

export interface HelpSectionProps {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

const HelpSection: FC<HelpSectionProps> = ({ opened, setOpened }) => {
  return (
    <Modal size={"75%"} opened={opened} onClose={() => setOpened(false)}>
      <Card>
        <Text>
          <h2>Help Section</h2>
          <p>
            Welcome to the AMS Search Portal! This guide will help you
            understand how to effectively use the search functionality to find
            business partners based on various criteria.
          </p>

          <h3>How to Use the Search</h3>
          <p>
            The AMS Search Portal allows you to search for business partners by
            entering multiple search terms. Here’s how it works:
          </p>
          <ol>
            <li>
              <strong>Adding Search Terms:</strong> Start by typing a keyword or
              phrase in the search bar. After entering each term, it will appear
              below the search bar. You can continue adding more terms to refine
              your search.
            </li>
            <li>
              <strong>Combined Search:</strong> The search results will show
              companies that match <strong>all</strong> the terms you have
              entered. For example, if you search for "TechCorp" and "London,"
              the system will only return companies named TechCorp that are
              associated with London.
            </li>
            <li>
              <strong>Paginated Results:</strong> The search results are
              displayed in pages. Use the navigation controls at the bottom of
              the search results to move between pages and view more companies.
              This helps in managing and viewing a large number of search
              results efficiently.
            </li>
          </ol>

          <h3>Tips for Effective Searching</h3>
          <ul>
            <li>
              <strong>Use Specific Terms:</strong> The more specific your search
              terms, the more accurate your results will be. For instance,
              searching for "Tech" will return more results than "TechCorp."
            </li>
            <li>
              <strong>Combine Multiple Criteria:</strong> To narrow down your
              search, combine different criteria. For example, searching for
              "Tech Solutions" and "Manchester" will yield a smaller, more
              relevant list of companies than either term alone.
            </li>
            <li>
              <strong>Partial Matches:</strong> You can enter partial names or
              terms to get broader results. For example, searching for "Tech"
              will return all companies with "Tech" in their name, such as
              "TechCorp" and "Tech Solutions."
            </li>
            <li>
              <strong>Review Results:</strong> If your search returns too many
              or too few results, try adjusting your search terms. Adding more
              specific terms will narrow the results, while using broader terms
              will expand them.
            </li>
          </ul>

          <h3>Example Searches</h3>
          <ul>
            <li>
              <strong>Finding a Company by Name and Location:</strong>
            </li>
            <p>
              Search Terms: "TechCorp", "London"
              <br />
              Result: Partners named TechCorp that are located in London.
            </p>
            <li>
              <strong>Locating by Contact Information:</strong>
            </li>
            <p>
              Search Term: "info@techcorp.co.uk"
              <br />
              Result: Partners with the email address info@techcorp.co.uk.
            </p>
          </ul>

          <h3>Troubleshooting</h3>
          <ul>
            <li>
              <strong>No Results Found:</strong> If you don't get any results,
              try broadening your search terms or double-checking the spelling
              and accuracy of your inputs.
            </li>
            <li>
              <strong>Too Many Results:</strong> If the search returns too many
              results, add more specific criteria to narrow down the list.
            </li>
            <li>
              <strong>Navigating Pages:</strong> If you have many results, make
              sure to navigate through the pages to find all relevant companies.
              Use the navigation controls at the bottom of the search results.
            </li>
          </ul>

          <Divider my="md" />
          <h3>Fields description</h3>

          <p>
            <ul>
              <li>
                <strong>Partner Name:</strong> Identification of a business
                Partner, not a username or account name.
              </li>
              <li>
                <strong>AMS ID:</strong> ID of an existing Partner in the AMS
                system.
              </li>
              <li>
                <strong>System:</strong> The system (trading portal) the partner
                was created in.
              </li>
              <li>
                <strong>System Account ID:</strong> The unique identifier for
                the partner in the trading portal (your system).
              </li>
              <li>
                <strong>Business Unit ID:</strong> Identifier of an existing
                Business Unit in AMS application.
              </li>
              <li>
                <strong>Organisation:</strong> Also business unit name. Used as
                display name for Auth0 organization.
              </li>
              <li>
                <strong>Organisation ID:</strong> Organisation ID that was
                assigned by SSO provider.
              </li>
              <li>
                <strong>Company Number:</strong> The company's registration
                number. See example on{" "}
                <Anchor
                  href="https://find-and-update.company-information.service.gov.uk/company/13078299"
                  target="_blank"
                  underline="hover"
                >
                  company-information.service.gov.uk
                </Anchor>
                .
              </li>
              <li>
                <strong>Address:</strong> The company's address.
              </li>
              <li>
                <strong>Contact Email:</strong> The company's primary contact
                email.
              </li>
              <li>
                <strong>Primary Contact:</strong> The company's primary contact.
              </li>
              <li>
                <strong>Phone Number:</strong> The company's primary contact
                phone number.
              </li>
            </ul>
          </p>
        </Text>
      </Card>
    </Modal>
  );
};

export default HelpSection;
