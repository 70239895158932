import React, { FC } from "react";
import { Center, Text } from "@mantine/core";

const NoResults: FC = () => {
  return (
    <Center h={100}>
      <Text>
        We couldn't find any matching partners. Please try adjusting your search
        criteria.
      </Text>
    </Center>
  );
};

export default NoResults;
