import { AppShell, useMantineTheme } from "@mantine/core";
import React, { FunctionComponent } from "react";
import Header from "./Header";

type Props = {
  children?: React.ReactNode;
};

const Navigation: FunctionComponent<Props> = ({ children }) => {
  const theme = useMantineTheme();

  return (
    <AppShell header={{ height: 70 }} padding="md">
      <AppShell.Header>
        <Header />
      </AppShell.Header>
      <AppShell.Main bg={theme.colors.gray[0]}>{children}</AppShell.Main>
    </AppShell>
  );
};

export default Navigation;
