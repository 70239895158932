import Qs from "qs";
import Axios, {
  AxiosError,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { envConfig } from "../../config";
import {
  acquireToken,
  loginRequest,
  msalInstance,
} from "../../config/auth/authConfig";

const ajaxInstance = Axios.create({
  baseURL: envConfig.apiUrl,
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: "comma" }),
});

export const ajaxService = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  ajaxInstance.defaults.headers.post["Content-Type"] = "application/json";
  ajaxInstance.defaults.headers.put["Content-Type"] = "application/json";
  ajaxInstance.interceptors.request.use(requestIntercept, (error) =>
    Promise.reject(error),
  );

  const { data } = await ajaxInstance({ ...config, ...options });
  return data;
};

const requestIntercept = async (request: InternalAxiosRequestConfig) => {
  let token;
  try {
    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      const tokenResponse = await msalInstance.acquireTokenSilent({
        account: account,
        scopes: loginRequest.scopes, // Match the scopes with your API needs
      });

      token = tokenResponse.accessToken;
    }
  } catch (error) {
    token = await acquireToken();
  }

  if (token && request?.headers) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
};

export type ErrorType<Error> = AxiosError<Error>;
