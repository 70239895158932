import React, { FC, useState } from "react";
import { Center, Loader, Stack } from "@mantine/core";
import SearchForm from "./components/SearchForm";
import { usePagination } from "./hooks/usePagination";
import PartnerCard from "./components/PartnerCard";
import PaginationWithSize from "./components/PaginationWithSize";
import ErrorAlert from "./components/ErrorAlert";
import NoResults from "./components/NoResults";
import { useSearchPartnerBy } from "../../api/generated/portal/portal";

const SearchPage: FC = () => {
  const [terms, setTerms] = useState<string[]>([]);
  const [pagination, setPagination] = usePagination();

  const { data, error, isLoading, isFetched } = useSearchPartnerBy(
    {
      terms,
      ...pagination,
    },
    {
      query: {
        enabled: terms.length > 0,
      },
    },
  );

  const renderErrorLoading = () => {
    if (error) return <ErrorAlert />;
    if (!isFetched) return null;
    if (!isLoading && !data?._embedded?.length) return <NoResults />;
  };

  return (
    <Center>
      <Stack w="95%" align="stretch" justify="flex-start">
        <SearchForm
          setTerms={setTerms}
          terms={terms}
          setPagination={setPagination}
        />

        {isLoading ? (
          <Center h={100}>
            <Loader size="xl" />
          </Center>
        ) : (
          renderErrorLoading() ||
          (isFetched && (
            <>
              {data?._embedded?.map((preview) => (
                <PartnerCard key={preview.partner.id} preview={preview} />
              ))}
              {data?._page && (
                <PaginationWithSize
                  page={data._page}
                  setPagination={setPagination}
                />
              )}
            </>
          ))
        )}
      </Stack>
    </Center>
  );
};

export default SearchPage;
