import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { InteractionStatus } from "@azure/msal-browser";
import UserMenu from "./UserMenu";

export const SignInOut = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return <UserMenu />;
  } else if (
    inProgress !== InteractionStatus.Startup &&
    inProgress !== InteractionStatus.HandleRedirect
  ) {
    return <SignInButton />;
  } else {
    return null;
  }
};
