import React, { FC } from "react";
import { HoverCard, Text } from "@mantine/core";
import { Address } from "../../../api/generated/schemas";

interface AddressProps {
  address: Address | undefined;
}

const getFullAddress = (address: Address): string | undefined => {
  const values = [
    address.addressLine1,
    address.city,
    address.county,
    address.postcode,
    address.country,
  ];

  return values.filter((value) => value).join(", ");
};

const AddressCell: FC<AddressProps> = ({ address }) => {
  if (!address) return null;

  return (
    <HoverCard shadow="md">
      <HoverCard.Target>
        <Text w={"200px"} size={"sm"} truncate="end">
          {getFullAddress(address)}
        </Text>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <div>
          {<Text size={"sm"}>{address?.addressLine1}</Text>}
          {<Text size={"sm"}>{address?.city}</Text>}
          {<Text size={"sm"}>{address?.county}</Text>}
          {<Text size={"sm"}>{address?.postcode}</Text>}
          {<Text size={"sm"}>{address?.country}</Text>}
        </div>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export default AddressCell;
