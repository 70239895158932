import React, { FC, forwardRef, useState } from "react";
import { Button, Group, Menu, rem, Text } from "@mantine/core";
import {
  IconChevronRight,
  IconHelpCircle,
  IconLogout,
  IconUserCircle,
} from "@tabler/icons-react";
import { useMsal } from "@azure/msal-react";
import HelpSection from "./HelpSection";

interface UserButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  name: string | undefined;
  email: string;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ name, email, ...others }: UserButtonProps, ref) => (
    <Button
      ref={ref}
      style={{
        transform: "none",
      }}
      variant="subtle"
      radius="lg"
      leftSection={<IconUserCircle color={"white"} />}
      rightSection={<IconChevronRight size="1rem" color={"white"} />}
      {...others}
    >
      <Group>
        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500} c={"white"}>
            {name}
          </Text>
          <Text c="dimmed" size="xs">
            {email}
          </Text>
        </div>
      </Group>
    </Button>
  ),
);

const UserMenu: FC = () => {
  const { accounts, instance } = useMsal();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <HelpSection opened={opened} setOpened={setOpened}></HelpSection>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <UserButton email={accounts[0].username} name={accounts[0].name} />
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            leftSection={
              <IconLogout style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={() => instance.logout()}
          >
            <Text size={"sm"}>Logout</Text>
          </Menu.Item>
          <Menu.Item
            leftSection={
              <IconHelpCircle style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={() => setOpened(true)}
          >
            Help
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

export default UserMenu;
